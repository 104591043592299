import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { CircularProgress, Container, Paper } from '@mui/material';
import { fetchOfficeDataByOfficeId, featchAgentListByOfficeId } from '../../services/ProfileService';
import { fetchListingCountByOfficeId, fetchListingCountByMacroOfficeId } from '../../services/ListingService';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import {  TENANTS } from '../../constants/constants';
import { Agent, Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { ProfileMetaTags, ProfileOfficeCard, ProfileOfficeMain } from '../../components/profile/ProfileComponentsIndex';


// Interface Declaration
interface OfficeRouteParam extends Record<string, string | undefined> {
    languagetag: string;
    pagetype: string;    
    countryorregion : string;
    cityorlozalzone: string;
    officename: string;
    officeid: string;
}
interface OfficeInterface {
    officeid?: any;
    

}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const OfficeProfile: React.FC<OfficeInterface> = (OfficeInterface) => {


    // Parameters Declaration
    const params = useParams<OfficeRouteParam>();
    const navigate = useNavigate(); // No Office Found Redirection; To Do in Agent Profile
    const { i18n } = useTranslation();

    // Hook Declaration
    const [office, setoffice] = useState<Office>();
    const [agentList, setAgentList] = useState<Agent[]>([]);
    const [officeListingCount, setOfficeListingCount] = useState(0);
    const [macroOfficeListingCount, setMarcoOfficListingCount] = useState(0);
    const [spokenLanguages, setSpokenLanguages] = useState<string[]>([]);
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // UseEffect Data Loading 
    useEffect(() => {
        const fetchOfficeProfileData = async () => {
            setIsLoading(true);

            let officeid = params.officeid ?? params.url;
            if (OfficeInterface.officeid !== undefined) {
                officeid = OfficeInterface.officeid;
            }
              
            if (officeid !== undefined && officeid !== null) {

                try {
                    const office = await fetchOfficeDataByOfficeId(officeid);

                    if (office === null || office?.OfficeId <= 0) {
                        let navigateUrl = "/ProfileSearch?searchType=office&page=1";
                        if (siteSettings && siteSettings.CountryID > 0)
                            navigateUrl = navigateUrl + "&countryId=" + siteSettings.CountryID;
                        navigate(navigateUrl);
                        return null;
                    }

                    setoffice(office);

                    if (office !== null) {
                        var result = await fetchListingCountByOfficeId(office.OfficeId);
                        setOfficeListingCount(result['@odata.count']);
                    }


                    if (office !== null && office.MacroOfficeId !== null && office.MacroOfficeId !== '') {
                        var result = await fetchListingCountByMacroOfficeId(parseFloat(office.MacroOfficeId));
                        setMarcoOfficListingCount(result['@odata.count'] ?? 0);
                    }


                    const aList = await featchAgentListByOfficeId(officeid);
                    if (aList !== null && aList.value !== null && aList.value.length > 0) {
                        setAgentList(aList.value?.map(a => a.content));

                        if (aList['@search.facets'] !== null && aList['@search.facets']?.['content/SpokenLanguages'] !== null && typeof (aList['@search.facets']?.['content/SpokenLanguages']) !== 'undefined') {
                            setSpokenLanguages(aList['@search.facets']?.['content/SpokenLanguages']?.map(item => item.value));
                        }
                    }

                } catch (error) {
                    console.log("Failed to fetchOfficeProfileData with error:", error);
                }

                setIsLoading(false);
            }

        };

        const loadSiteDataSource = async () => {
            try {
                await fetchLookupTranslations().then((lookupList) => {
                    if (lookupList == null) {
                        console.log("lookupList loads nothing");
                    } else {
                        setLookupList(lookupList);
                    }
                });

                await fetchSiteSettings().then((siteSettings) => {
                    if (siteSettings == null) {
                        console.log("siteSettings loads nothing");
                    } else {
                        setSiteSettings(siteSettings);
                    }
                });

            } catch (error) {
                console.error('Agent Profile: failed to load site data source', error);
            }
        };

        loadSiteDataSource();
        fetchOfficeProfileData();

    }, [i18n.language]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
            </div>
        );
    }

    return (
        <div>
            {/* Set Helmet properties */}
            <ProfileMetaTags profileMetaTags={{ office: office, lookupList: lookupList, siteSettings: siteSettings }} />

            {/* Top Container */}
            <div>

                <Container className="text-align-left light" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>


                    <ProfileOfficeCard profileOfficeCard={{
                        office: office,
                        lookupList: lookupList,
                        siteSettings: siteSettings,
                        officeListingCount: officeListingCount,
                        macroOfficeListingCount: macroOfficeListingCount,
                        spokenLanguages: spokenLanguages,
                    }} />

                </Container>

            </div>

            {/* Middle Container */}
            <div style={{ backgroundColor: 'white' }}>

                    <Container className="text-align-left" sx={{
                        paddingTop: {
                            xs: '2.5rem',
                            md: '3rem'
                        },
                        paddingBottom: {
                            xs: '2.5rem',
                            md: '3rem'
                        },
                    }}>

                    <ProfileOfficeMain profileOfficeMain={{
                        office: office,
                        agentList: agentList,
                        spokenLanguages: spokenLanguages,
                        lookupList: lookupList,
                        siteSettings: siteSettings,
                        officeListingCount: officeListingCount,
                        macroOfficeListingCount: 0 // To Do
                    }} />

                </Container>

            </div>

            {/* Bottom container */}
            {/* Profile Office Footer,  It includes Recently sold rented listed carousels component in Phase II*/}

        </div>);
};

export default OfficeProfile;