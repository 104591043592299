import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MetaTags from '../components/utils/seo/MetaTags';
import { useContent } from './ContentContext';
import { useTranslation } from 'react-i18next';
import LanguageMenu from '../components/utils/LanguageMenu';
import parse from 'html-react-parser';
import SpotLight from '../components/utils/spot-light';
import QuickSearch from '../components/utils/quicksearch';
import { fi } from 'date-fns/locale';
import { useSearch } from '../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../reducer/actionTypes';
import { handleClickSearch } from '../components/utils/helper/handleClickSearch';
import { SiteSettings } from '../types/TypesIndex';
import { fetchSiteSettings } from '../services/SiteSettingService';

interface Option {
    group: string;
    title: string;
    type: string;
    expression: string;
    id: string
}


const HomeCMS: React.FC = () => {
    const manageContent = useContent();
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [metaData, setMetaData] = useState({ title: '', description: '' });
    const { t, i18n } = useTranslation();
    const [value, setValue] = React.useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = window.innerWidth <= 600;
    const [inputValue, setInputValue] = useState('');
    const { state, dispatch } = useSearch();

    const toggleDirection = () => {
        // Your logic to toggle language direction (LTR/RTL)
    };

    const toggleLanguages = (lang: string) => {
        i18n.changeLanguage(lang);
    };

 
    const handleClick = useCallback((option: Option, search: boolean = false) => (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.preventDefault();
        const payload = { ...state.searchState };
        switch (option.type) {
            case 'province':
                payload.Province.push(parseInt(option.id));
                break;
            case 'city':
                payload.City.push(parseInt(option.id));
                break;
            case 'localzone':
                payload.LocalZone.push(option.expression);
                break;
            case 'streetName':
                payload.StreetName = option.expression;
                break;
            case 'MLSID':
                payload.MLSID = option.expression;
                break;
            default:
                break;
        }

        dispatch({ type: SET_SEARCH_STATE, payload });
        handleClickSearch(option, true, state.searchState, dispatch, location, navigate)
      
    }, [ location.pathname, navigate]);

    useEffect(() => {

        const LoadPageContent = async () => {
            try {
                manageContent.setFooter(true);
                manageContent.setHeader(true);

                // use the site default language code from domain js when the current language does not belong to site supported languages
                const siteSettings: SiteSettings = await fetchSiteSettings();
                let siteLanguages = siteSettings?.RegionSupportedLanguages?.map(l => l.FullLanguageCode);

                let language = i18n.language ?? window.defaultlanguage;
                if (!language || ( siteLanguages && !siteLanguages.includes(language))) {
                    language = window.defaultlanguage ?? "en-US";
                    i18n.language = language;
                }


                // const language = i18n.language ?? 'en-US';
                fetch(`/cms/${language}/`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Error fetching data');
                        }
                        const data = response.text();

                        return data;
                    })
                    .then(html => {
                        const div = document.createElement('div');

                        div.innerHTML = html.replace(/https?:\/\/[^/]+\/uploads\//, 'cms/uploads/');

                        //  console.log(div.innerHTML);

                        setHtmlContent(div.innerHTML);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            } catch (error) {
                navigate('/');
            }
            finally {
                setIsLoading(false);
            }
        }
        LoadPageContent();

    }, [i18n.language, window.tenantid]);

    const options = {
        replace: (domNode: any) => {
            // Handle the Language Menu replacement
            if (domNode.name === 'div' && domNode.attribs?.class?.includes('dropdown locale-select')) {
                return <LanguageMenu data={{ toggleDirection, toggleLanguages }} />;
            }

            // Handle <section data-spot-light-widget="">
            if (domNode.name === 'section' && domNode.attribs['data-spot-light-widget'] !== undefined) {
                return <SpotLight value={0} />;
            }

            // Replace <section data-quicksearch-widget="">
            if (domNode.name === 'section' && domNode.attribs['data-quicksearch-widget'] !== undefined) {
                return <QuickSearch handleClick={handleClick} inputValue={inputValue} isMobile={isMobile} />;
            }
        },
    };

    return (
        <>
            <MetaTags metaTags={{
                title: metaData.title,
                ogTitle: metaData.title,
                description: metaData.description,
                url: window.location.href,
            }} />

            {!isLoading && (
                <>
                    {parse(htmlContent, options)}
                </>
            )}
        </>
    );
};

export default HomeCMS
