import React, { useState, useEffect, useCallback } from 'react';
import {
    Autocomplete,
    TextField,
    Box,
    Stack,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';

import { handleClickSearch } from '../utils/helper/handleClickSearch';
import { debounce } from 'lodash';


interface Props {
    apiBaseUrl?: string;
    onOptionSelect?: (value: string) => void;
    onDetailsSelect?: (value: any) => void;
    onSearchClick?: (option: Option, search: boolean) => void;
}

interface Option {
    group: string;
    title: string;
    type: string;
    expression: string;
    id: string
}
const normalizeString = (str: string): string => {
    return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim() : '';
  };
const normalizeSpaces = (str: string) => str.replace(/\s+/g, ' ').trim();

const normalizeCommas = (str: string) => str.replace(/,\s*/g, ', ');

const normalizeAndTrim = (str: string) => normalizeSpaces(normalizeString(str).trim());

const toCamelCase = (str: string) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const normalizeAndTrimCamelCase = (str: string) => toCamelCase(normalizeSpaces(normalizeAndTrim(normalizeCommas(normalizeString(str).trim()))));

const fetchOptions = async (inputValue: string, t: any): Promise<Option[]> => {
    const url = `${process.env.REACT_APP_API_SEARCH_LISTING}`;

    const search = inputValue.trim();


    const filter = `content/MacroRegionId eq ${window.macroregionid} and content/OnHoldListing eq false`; //remove hardcode tenantid

    const data = {
        count: false,
        skip: 0,
        top: 150,
        filter: filter,
        select: [
            'content/TitleAddress',
            'content/City',
            'content/CityID',
            'content/RegionId',
            'content/Province',
            'content/ProvinceID',
            'content/PropertyCategoryUID',
            'content/MacroRegionId',
            'content/RegionalZone',
            'content/District',
            'content/FullAddress',
            'content/StreetName',
            'content/LocalZone',
            'content/LocalZoneID',
            'content/DevelopmentName',
            'content/GeoDatas'
        ].join(', '),
        searchFields: [
            'content/City',
            'content/Province',
            'content/RegionalZone',
            'content/District',
            'content/FullAddress',
            'content/StreetName',
            'content/LocalZone',
            'content/GeoDatas/City',
            'content/GeoDatas/Province',
            'content/GeoDatas/RegionalZone',
            'content/GeoDatas/FullAddress',
            'content/GeoDatas/LocalZone'
        ].join(', '),

        search: search
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "referer": window.location.href,
                "origin": window.location.origin,
                "User-Agent": window.navigator.userAgent
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();

        if (!result.value) {
            console.error('Response does not contain value property', result);
            return [];
        }

        const addOption = (optionsArray: Option[], condition: boolean, option: Option) => {
            if (condition) {
                optionsArray.push(option);
            }
        };

        const transformedOptions: Option[] = result.value.flatMap((item: any) => {

            const optionsArray: Option[] = [];
            addOption(optionsArray, item.content.Province, {
                title: item.content.Province,
                group: t('FreeTextSearch_Provinces'),
                type: 'province',
                expression: item.content.Province,
                id: item.content.ProvinceID
            });

            addOption(optionsArray, item.content.City && normalizeString(item.content.City).includes(normalizeString(inputValue)), {
                title: `${item.content.City ? item.content.City.trim(): ""}, ${item.content.Province}`,
                group: t('FreeTextSearch_Cities'),
                type: 'city',
                expression: item.content.City,
                id: item.content.CityID
            });

            item.content.GeoDatas.map((geoData: any, index: number) => {
                addOption(optionsArray, geoData.City && normalizeString(geoData.City).includes(normalizeString(inputValue)), {
                    title: `${geoData.City ? geoData.City.trim() : ""}, ${geoData.Province}`,
                    group: t('FreeTextSearch_Cities'),
                    type: 'city',
                    expression: geoData.City,
                    id: item.content.CityID
                });

                addOption(optionsArray, geoData.LocalZone && normalizeString(geoData.LocalZone.trim()).includes(normalizeString(inputValue.trim())), {
                    title: `${(geoData.LocalZone ? geoData.LocalZone.trim() : "")}, ${(geoData.City ? geoData.City.trim() : "")}, ${(geoData.Province)}`,
                    group: t('FreeTextSearch_LocalZones'),
                    type: 'localzone',
                    expression: geoData.LocalZone,
                    id: item.content.LocalZoneID
                });
            });

            addOption(optionsArray, item.content.LocalZone && normalizeString(item.content.LocalZone).includes(normalizeString(inputValue)), {
                title: `${item.content.LocalZone ? item.content.LocalZone.trim() : ""}, ${item.content.City ? item.content.City.trim() : ""}, ${item.content.Province ? item.content.Province.trim() : ""}`,
                group: t('FreeTextSearch_LocalZones'),
                type: 'localzone',
                expression: item.content.LocalZone,
                id: item.content.LocalZoneID
            });

            addOption(optionsArray, item.content.StreetName && normalizeString(item.content.StreetName).includes(normalizeString(inputValue)), {
                title: `${item.content.StreetName ? item.content.StreetName.trim() : ""}, ${item.content.LocalZone? item.content.LocalZone.trim() : ""}, ${item.content.City ? item.content.City.trim() : ""}`,
                group: t('FreeTextSearch_Street'),
                type: 'streetName',
                expression: item.content.StreetName,
                id: item.content.StreetName
            });

            addOption(optionsArray, item.content.DevelopmentName, {
                title: `${item.content.DevelopmentName}`,
                group: t('FreeTextSearch_Development'),
                type: 'DevelopmentName',
                expression: item.content.DevelopmentName,
                id: null
            });

            return optionsArray;
        });

        // Remove duplicates
        const uniqueOptionsMap = new Map<string, Option>();
        transformedOptions.forEach(option => {
            
           if (!uniqueOptionsMap.has(option.id)) {
              uniqueOptionsMap.set(option.id, option);
            }
        });

        const uniqueOptions = Array.from(uniqueOptionsMap.values());

        return uniqueOptions.sort((a, b) => a.title.localeCompare(b.title));
    } catch (error) {
        console.error('Failed to fetch autocomplete suggestions:', error);
        return [];
    }
};

const isMLSID = (input: string): boolean => {
    const regex = /^\d+-\d+$/;
    return regex.test(input.trim());
};

const ListingSearchFreeTextSearch: React.FC<Props> = ({ apiBaseUrl = '', onOptionSelect, onDetailsSelect, onSearchClick }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useSearch();
    const [options, setOptions] = useState<Option[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const groupOrder = ['province', 'city', 'localzone', 'streetName', 'DevelopmentName'];

    const debouncedFetchOptions = useCallback(debounce(async (input: string) => {
        if (input.length > 2) {
            const options = await fetchOptions(input, t);
            setOptions(options);
        }
    }, 290), [t]);

    useEffect(() => {

        if (inputValue.trim() === '' && inputValue.length > 2) {
            setOptions([]);
            return;
        }
        debouncedFetchOptions(inputValue);
    }, [inputValue, debouncedFetchOptions]);



    const handleInputChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
        setInputValue(newValue);
        setOpen(newValue.trim().length > 0);
    }, []);

    const updateSearchParams = (searchParams: URLSearchParams, key: string, values: string[]) => {
        searchParams.delete(key);

        values.forEach(value => {
            if (value.trim() !== 'NaN') {
                searchParams.append(key, value);
            }
        });
    };

    const renderBoldText = (text: string, highlight: string) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

        return (
            <Typography component="span">
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <Typography component="span" sx={{ fontWeight: 'bold' }} key={index}>
                            {part}
                        </Typography>
                    ) : (
                        part
                    )
                )}
            </Typography>
        );
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" useFlexGap sx={{ width: '100%', minHeight: '40px' }}>
            <Box sx={{ width: '100%' }}>
                <Autocomplete
                    fullWidth
                    sx={{
                        maxWidth: '100% !important',
                        width: '100% !important',
                        '& .MuiAutocomplete-popupIndicator': {
                            display: 'none',
                        },
                    }}
                    id="listing-search-autocomplete"
                    //value={null}
                    //value={null}
                    noOptionsText=""
                    options={options.sort((a, b) => groupOrder.indexOf(a.type) - groupOrder.indexOf(b.type))}
                    //filterOptions={(x) => (options.length === 0 ? [] : x)}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.title}
                    inputValue={inputValue}
                    onChange={(event, newValue) => {
                        const ps = { ...state.searchState };
                        debugger; // Make a shallow copy of searchState to avoid mutating it directly
                        const searchParams = new URLSearchParams(location.search);

                        if (newValue !== null) {
                            if (newValue.type === 'city') {
                                ps.City = Array.isArray(ps.City) ? ps.City : []; // Ensure ps.City is an array
                                ps.CityNM = Array.isArray(ps.CityNM) ? ps.CityNM : []; // Ensure ps.CityNM is an array
                                if (!ps.City.includes(parseInt(newValue.id))) {
                                    ps.City.push(parseInt(newValue.id));
                                    ps.CityNM.push(newValue.id + "-" + newValue.title);
                                }
                            }
                            if (newValue.type === 'province') {
                                ps.Province = Array.isArray(ps.Province) ? ps.Province : []; // Ensure ps.Province is an array
                                ps.ProvinceNM = Array.isArray(ps.ProvinceNM) ? ps.ProvinceNM : []; // Ensure ps.ProvinceNM is an array
                                if (!ps.Province.includes(parseInt(newValue.id))) {
                                    ps.Province.push(parseInt(newValue.id));
                                    ps.ProvinceNM.push(newValue.id + "-" + newValue.title);
                                }
                            }

                            if (newValue.type === 'localzone') {
                                ps.LocalZone = Array.isArray(ps.LocalZone) ? ps.LocalZone : []; // Ensure ps.LocalZone is an array
                                ps.LocalZoneNM = Array.isArray(ps.LocalZoneNM) ? ps.LocalZoneNM : []; // Ensure ps.LocalZoneNM is an array
                                if (!ps.LocalZone.includes(parseInt(newValue.id))) {
                                    ps.LocalZone.push(parseInt(newValue.id));
                                    ps.LocalZoneNM.push(newValue.id + "-" + newValue.title);
                                }
                            }
                            if (newValue.type === 'streetName') {
                                ps.StreetName = newValue.expression; // StreetName is not an array, so we directly assign it
                            }

                           

                            // Update searchParams with the new state values
                            updateSearchParams(searchParams, 'City', ps.City.map(city => city.toString()));
                            updateSearchParams(searchParams, 'CityNM', ps.CityNM);
                            updateSearchParams(searchParams, 'Province', ps.Province.map(province => province.toString()));
                            updateSearchParams(searchParams, 'ProvinceNM', ps.ProvinceNM);
                            updateSearchParams(searchParams, 'LocalZone', ps.LocalZone.map(localZone => localZone.toString()));
                            updateSearchParams(searchParams, 'LocalZoneNM', ps.LocalZoneNM);
                            if (ps.StreetName && ps.StreetName.trim() !== 'NaN') searchParams.set('StreetName', ps.StreetName);

                            // Dispatch updated search state and navigate
                            dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, ...ps } });
                            
                            navigate({ search: searchParams.toString() });
                        }  if (isMLSID(inputValue)) {
                            const mlsidOption: Option = { group: 'MLSID', title: inputValue, type: 'MLSID', expression: inputValue, id: null };
                            handleClickSearch(mlsidOption, true, state.searchState, dispatch, location, navigate)(event as unknown as React.MouseEvent<HTMLDivElement>);
                            navigate(location.pathname);
                        } else {
                            navigate(location.pathname);
                        }
                    }}

                    open={open && options.length > 0}
                    onClose={() => setOpen(false)}
                    onInputChange={handleInputChange}
                    onKeyDown={(event) => {

                        if (event.key === 'Enter') {

                            const matchedOption = options.find(option => option.title === inputValue);
                            if (matchedOption) {
                                handleClickSearch(matchedOption, true, state.searchState, dispatch, location, navigate)(event as unknown as React.MouseEvent<HTMLDivElement>);
                            } else if (isMLSID(inputValue)) {
                                const mlsidOption: Option = { group: 'MLSID', title: inputValue, type: 'MLSID', expression: inputValue, id: null };
                                handleClickSearch(mlsidOption, true, state.searchState, dispatch, location, navigate)(event as unknown as React.MouseEvent<HTMLDivElement>);
                            } else if (inputValue.trim() !== '') {
                                const mlsidOption: Option = { group: 'FreeText', title: inputValue, type: 'FreeText', expression: inputValue, id: null };
                                handleClickSearch(mlsidOption, true, state.searchState, dispatch, location, navigate)(event as unknown as React.MouseEvent<HTMLDivElement>);
                            }
                            else {

                               //
                               
                               
                               //handleOptionSelect();
                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ maxWidth: '100% !important', width: '100% !important' }}
                            placeholder={`${t('FreeTextSearch_EmptyBoxMessage')}`}
                            variant="standard"
                            {...params}
                        />
                    )}
                    renderOption={(props, option, index) => {
                        const { key, ...rest } = props;
                        const uniqueKey = option.id ? `${option.id}-${index}` : `${option.title}-${index}`;
                        return (
                            <li key={uniqueKey} {...rest}>
                                {renderBoldText(option.title, inputValue)}
                            </li>
                        );
                    }}
                    renderGroup={(params) => (
                        <div key={params.key}>
                            <div style={{ color: 'var(--primary-color)', backgroundColor: 'var(--select-menu-active-color)', padding: '4px 10px' }}>{params.group}</div>
                            <ul>{params.children}</ul>
                        </div>
                    )}
                />
            </Box>
        </Stack>
    );
};

export default ListingSearchFreeTextSearch;
