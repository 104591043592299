import React, { useState, useEffect } from 'react';
import MetaTags from '../components/utils/seo/MetaTags';
import { useContent } from './ContentContext';
import { useTranslation } from 'react-i18next';
import LanguageMenu from '../components/utils/LanguageMenu';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import OfficeProfile from './profile/OfficeProfile';
import Listing from '../pages/listing/ListingSearch';
import { SiteSettings } from '../types/TypesIndex';
import { fetchSiteSettings } from '../services/SiteSettingService';

const CMSPage: React.FC = () => {
    const manageContent = useContent();
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [metaData, setMetaData] = useState({ title: '', description: '' });
    const [showOfficeProfile, setShowOfficeProfile] = useState(false);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [ parameter, setParameter ] = useState<string>('');

    const toggleDirection = () => {
        // Your logic to toggle language direction (LTR/RTL)
    };

    const toggleLanguages = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const fetchCMSContent = async (language: string, path: string) => {
     
        try {
            const response = await fetch(`/cms/${language}${path}?aposRefresh`);
            if (!response.ok) {
                throw new Error('Error fetching data');
            }
            let html = await response.text();
            // Remove <html>, <head>, and <body> tags
           // html = html.replace(/<\/?(html|head|body)[^>]*>/g, '');
            const div = document.createElement('div');
            div.innerHTML = html;
            const copyrightYearElement = div.querySelector('#copyrightYear');
            if (copyrightYearElement) {
                copyrightYearElement.textContent = new Date().getFullYear().toString();
            }
            setHtmlContent(div.innerHTML);
           // setIsLoading(false);
            setShowOfficeProfile(false);
        } catch (error) {
            setShowOfficeProfile(true);
            // manageContent.setFooter(false);
            //manageContent.setHeader(false);
            handleNotFound();
        }
        finally {
            manageContent.setFooter(true);
            manageContent.setHeader(true);
           // setIsLoading(false);
        }
    };

    const handleNotFound = async () => {
        if (window.tenantid !== undefined) {
            await fetchShortLink();
        } else {
            await fetchConfigRegion();
        }
    };

    const fetchShortLink = async () => {
       // const path = window.location.pathname;
        const datarequest = {
            count: false,
            skip: 0,
            top: 1,
            searchMode: "any",
            queryType: "simple",
            select: "content/RedirectURL",
            filter: `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid} and content/SourceUrl eq '${window.location.pathname.replace('/', '')}'`,
        };
        const body = JSON.stringify(datarequest);
        try {
            const response = await fetch('/ShortLink', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: body });
            const data = await response.json();
            const siteSettings: SiteSettings = await fetchSiteSettings();
 
          //  case data
            if (data.value[0]?.content?.RedirectURL) {
                //window.location.search = "?Bedrooms=1&MacroPropertyTypeUIDs=3246";

               //type = "office",
               // id = "2222222",
               // querystrings = "?Bedrooms=1&MacroPropertyTypeUIDs=3246"

            //    manageContent.setFooter(false);
            //    manageContent.setHeader(false);
                navigate('/' + data.value[0].content.RedirectURL);
            }
            else 
            { 
                // use the site default language code from domain js when the current language does not belong to site supported languages
                let siteLanguages = siteSettings?.RegionSupportedLanguages?.map(l => l.FullLanguageCode);

                let language = i18n.language ?? window.defaultlanguage;
                if (!language || ( siteLanguages && !siteLanguages.includes(language))) {
                    language = window.defaultlanguage ?? "en-US";
                    i18n.language = language;
                }

                fetchCMSContent(language, window.location.pathname)
            }
        } catch (error) {
            console.error('Error fetching short link:', error);
        }
    

        
       
        setShowOfficeProfile(true);
       // setIsLoading(true);
        // Simulate fetching short link data
        //const data = { value: [{ content: { Type: "Office" } }] }; // Simulated data
        //if (data.value[0]?.content?.Type === "Office") {
        //    setShowOfficeProfile(true);
        // } else {
        //     setShowOfficeProfile(false);
        // }
    };

    const fetchConfigRegion = async () => {
        try {
            const response = await fetch('/ConfigRegion');
            const data = await response.json();
            window.regionDomain = data.regiondomain;
            window.regionid = data.regionid;
            window.macroregionid = data.macroregionid;
            window.tenantid = data.tenantid;
            window.theme = data.theme;
            window.defaultlanguage  = data.defaultlanguage;
          //  await fetchShortLink();
        } catch (error) {
            console.error('Error fetching config region:', error);
        }
    };

    useEffect(() => {
        const path = window.location.pathname;

        //const language = i18n.language ?? 'en-US';
        fetchShortLink();
       // fetchCMSContent(language, path);
    }, [i18n.language]);

    useEffect(() => {
        fetchConfigRegion();
    }, []);

    const options = {
        replace: (domNode: any) => {
            if (domNode.name === 'div' && domNode.attribs.class && domNode.attribs.class.includes('dropdown locale-select')) {
                return <LanguageMenu data={{ toggleDirection: toggleDirection, toggleLanguages: toggleLanguages }} />;
            }
        },
    };

    return (
        <>
            <MetaTags metaTags={{
                title: metaData.title,
                ogTitle: metaData.title,
                description: metaData.description,
                url: window.location.href,
            }}
            />
            {showOfficeProfile ? (
                <OfficeProfile officeid={2000}  />
            ) : (
                !showOfficeProfile && (
                    <> {parse(htmlContent, options)} </>
                )
            )}
        </>
    );
};

export default CMSPage;
