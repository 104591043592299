import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, MenuItem, Box, IconButton, InputLabel, TextField, Select, ListSubheader, FormControl, SelectChangeEvent, Popover, Grid, useTheme, useMediaQuery } from '@mui/material';
import { ArrowDropUpOutlined } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LookupItem, SiteSettings } from '../../types/TypesIndex';
import ListingSearchFilters from '../../types/Listing/ListingSearchFilters';
import ListingSearchFilterLookupItem from '../../types/Listing/ListingSearchFilterLookupItem';
import ListingSearchFilterMacroPropertyType from '../../types/Listing/ListingSearchFilterMacroPropertyType';
import { getListingSearchFilterLookupItemList, getListingSearchFilterMacroPropertyTypeList } from '../utils/helper/LookupHelper';
import { formatCurrency, formatNumberWithRegionSetting } from '../../components/utils/helper/FormatNumberHelper';
import { fetchListingFilters, fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchLookupTranslations, fetchMacroPropertyTypesTranslations } from '../../services/TranslationService';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { LISTINGSEARCHFILTER, PAGE_TYPE, PROPERTY_TYPES, TRANSACTION_TYPES } from '../../constants/constants';
import { updateLocationUrl } from '../utils/helper/FunctionHelper';


interface ListingSearchFilterDropdownsProps {
    page?: string;
}

const ListingSearchFilterDropdowns: React.FC<ListingSearchFilterDropdownsProps> = ({ page }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useSearch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    // Hook Declaration
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [listingSearchFilters, setListingSearchFilters] = useState<ListingSearchFilters>();
    const [commMacroPropertyTypesMenuList, setCommMacroPropertyTypesMenuList] = useState<ListingSearchFilterMacroPropertyType[]>([]);
    const [resMacroPropertyTypesMenuList, setResMacroPropertyTypesMenuList] = useState<ListingSearchFilterMacroPropertyType[]>([]);
    const [landMacroPropertyTypesMenuList, setLandMacroPropertyTypesMenuList] = useState<ListingSearchFilterMacroPropertyType[]>([]);

    const [bedroomsMenuList, setBedroomsMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [bathroomsMenuList, setBathroomsMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [priceCurreny, setPriceCurrency] = useState<string>('');
    const [minPriceMenuList, setMinPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [maxPriceMenuList, setMaxPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [rentalMinPriceMenuList, setRentalMinPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [rentalMaxPriceMenuList, setRentalMaxPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [priceRangeTitleLabel, setPriceRangeTitleLabel] = useState(t('Search_PriceRange'));
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const selectRef = useRef<HTMLSelectElement>(null);

    const handleMacroPropertyTypeChange = (event: SelectChangeEvent<string>) => {
        // To Do: when multi selection is needed: let val = (event.target.value && parseFloat(event.target.value) > 0) ? ((state.searchState.MacroPropertyTypeUIDs !== null && state.searchState.MacroPropertyTypeUIDs.length > 0 )?state.searchState.MacroPropertyTypeUIDs + "," + event.target.value : event.target.value): null;
        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, MacroPropertyTypeUIDs: val } });

        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.MACROPROPERTYTYPEUIDS, val);
        }
    };

    const handleBedroomChange = (event: SelectChangeEvent<string>) => {

        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, Bedrooms: val } });
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.BEDROOMS, val);
        }
    };

    const handleBathroomChange = (event: SelectChangeEvent<string>) => {
        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.BATHROOMS, val);
        }
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, Bathrooms: val } });
    };

    useEffect(() => {

        const loadListingSearchFiltersRenderList = async () => {

            try {
                const languageCode = i18n.language;

                const listingfilter: ListingSearchFilters = await fetchListingFilters();
                const lookupList: LookupItem[] = await fetchLookupTranslations();
                const macroPropertyTypes: LookupItem[] = await fetchMacroPropertyTypesTranslations();
                const siteSettings: SiteSettings = await fetchSiteSettings();


                setListingSearchFilters(listingfilter);
                setLookupList(lookupList);
                setSiteSettings(siteSettings);

                const commMacroPropertyTypes = [...new Set(listingfilter.PropertyTypeList.filter(m => m.ComRes === PROPERTY_TYPES.COMMERCIAL.toString()).filter(m => m.MacroListingCount > 0).map(m => m.MacroID))];
                setCommMacroPropertyTypesMenuList(getListingSearchFilterMacroPropertyTypeList(commMacroPropertyTypes, macroPropertyTypes))
                const resMacroPropertyTypes = [...new Set(listingfilter.PropertyTypeList.filter(m => m.ComRes === PROPERTY_TYPES.RESIDENTIAL.toString()).filter(m => m.MacroListingCount > 0).map(m => m.MacroID))];
                setResMacroPropertyTypesMenuList(getListingSearchFilterMacroPropertyTypeList(resMacroPropertyTypes, macroPropertyTypes));
                const landMacroPropertyTypes = [...new Set(listingfilter.PropertyTypeList.filter(m => m.ComRes === PROPERTY_TYPES.LAND.toString()).filter(m => m.MacroListingCount > 0).map(m => m.MacroID))];
                setLandMacroPropertyTypesMenuList(getListingSearchFilterMacroPropertyTypeList(landMacroPropertyTypes, macroPropertyTypes));

                setBedroomsMenuList(getListingSearchFilterLookupItemList(listingfilter.BedRooms, lookupList));
                setBathroomsMenuList(getListingSearchFilterLookupItemList(listingfilter.Bathrooms, lookupList));


                let curPriceCurreny = siteSettings.DefaultCurrency;
                let minPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "MinPriceRange").map(p => p.UID).join(",");
                let maxPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "MaxPriceRange").map(p => p.UID).join(",");
                let rentalMinPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "RentalMinPriceRange").map(p => p.UID).join(",");
                let rentalMaxPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "RentalMaxPriceRange").map(p => p.UID).join(",");

                setPriceCurrency(curPriceCurreny);
                setMinPriceMenuList(getListingSearchFilterLookupItemList(minPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));

                //setMaxPriceMenuList(getListingSearchFilterLookupItemList(maxPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));
                //Add + sign to the last item
                const formattedMaxPriceMenuList = getListingSearchFilterLookupItemList(maxPriceRangeLst, lookupList)
                    .sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue))
                    .map(p => ({
                        ...p, // spread the other properties
                        DisplayValue: formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings) // format the DisplayValue
                    }));
                if (formattedMaxPriceMenuList.length > 0) {
                    const lastItem = formattedMaxPriceMenuList[formattedMaxPriceMenuList.length - 1];
                    lastItem.DisplayValue = `${lastItem.DisplayValue} +`;
                }
                setMaxPriceMenuList(formattedMaxPriceMenuList);

                setRentalMinPriceMenuList(getListingSearchFilterLookupItemList(rentalMinPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));

                //setRentalMaxPriceMenuList(getListingSearchFilterLookupItemList(rentalMaxPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));
                //Add + sign to the last item
                const formattedRentalMaxPriceMenuList = getListingSearchFilterLookupItemList(rentalMaxPriceRangeLst, lookupList)
                    .sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue))
                    .map(p => ({
                        ...p, // spread the other properties
                        DisplayValue: formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings) // format the DisplayValue
                    }));

                if (formattedRentalMaxPriceMenuList.length > 0) {
                    const lastItem = formattedRentalMaxPriceMenuList[formattedRentalMaxPriceMenuList.length - 1];
                    lastItem.DisplayValue = `${lastItem.DisplayValue} +`;
                }
                setRentalMaxPriceMenuList(formattedRentalMaxPriceMenuList);

            } catch (error) {
                console.error('Error: loadListingSearchFiltersRenderList on ListingSearchFilterDropdowns', error);
            }
        };

        loadListingSearchFiltersRenderList();

    }, [i18n.language, state.searchState.MacroPropertyTypeUIDs]);

    useEffect(() => {
        if (selectRef.current) {
            selectRef.current.value = state.searchState.MacroPropertyTypeUIDs ?? '-1';
        }
    }, [state.searchState.MacroPropertyTypeUIDs]);


    // Price Section
    useEffect(() => {
        updatePriceRangeTitleLabel(state.searchState.PriceMin, state.searchState.PriceMax);
    }, [i18n.language, state.searchState.PriceMin, state.searchState.PriceMax]);

    const openPriceRangePopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePriceRangePopover = () => {
        setAnchorEl(null);
    };

    const priceRangePopoverOpen = Boolean(anchorEl);
    const priceRangePopoverId = priceRangePopoverOpen ? 'price-range-popover' : undefined;


    //We may remove this FN is not been call
    const handleMinPriceTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updatePriceRangeTitleLabel(event.target.value, state.searchState.PriceMax);
        refreshMinPriceMenuList(event.target.value.toString());
        refreshMaxPriceMenuList(event.target.value.toString());


        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMIN, val);
        }

        dispatch({
            type: SET_SEARCH_STATE,
            payload: {
                ...state.searchState,
                PriceMin: val
            }
        });
    };


    //We may remove this FN is not been call
    const handleMaxPriceTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        updatePriceRangeTitleLabel(state.searchState.PriceMax, event.target.value);
        refreshMinPriceMenuList(event.target.value.toString());
        refreshMaxPriceMenuList(event.target.value.toString());

        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMAX, val);
        }

        dispatch({
            type: SET_SEARCH_STATE,
            payload: {
                ...state.searchState,
                PriceMax: val
            }
        });
    };

    const handleMinPriceMenuItemChange = (value: string) => {

        updatePriceRangeTitleLabel(value, state.searchState.PriceMax);
        setAnchorEl(null);
        refreshMaxPriceMenuList(value);

        let val = (value && parseFloat(value) > 0) ? value : null;
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMIN, val);
        }

        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PriceMin: value } });
    };

    const handleMaxPriceMenuItemChange = (value: string) => {

        updatePriceRangeTitleLabel(state.searchState.PriceMax, value);
        setAnchorEl(null);
        refreshMinPriceMenuList(value);

        let val = (value && parseFloat(value) > 0) ? value : null;
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMAX, val);
        }
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PriceMax: value } });
    };

    const refreshMinPriceMenuList = (value: string) => {
        let type = state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString() ? "RentalMinPriceRange" : "MinPriceRange";
        let minPriceUIDStr = listingSearchFilters?.PriceRangeList.filter(p => p.Code === priceCurreny).filter(p => p.Type === type).map(p => p.UID).join(",") ?? "";
        if (minPriceUIDStr === null || minPriceUIDStr === "")
            return;

        let minPriceList;
        if (value === "")
            minPriceList = getListingSearchFilterLookupItemList(minPriceUIDStr, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));
        else
            minPriceList = getListingSearchFilterLookupItemList(minPriceUIDStr, lookupList).filter(p => Number(p.Value) >= Number(value)).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));

        if (state.searchState.TransactionTypeUID !== TRANSACTION_TYPES.FORRENT.toString()) {
            setMinPriceMenuList(minPriceList);
        } else {
            setRentalMinPriceMenuList(minPriceList);
        }
    }

    const refreshMaxPriceMenuList = (value: string) => {
        let type = state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString() ? "RentalMaxPriceRange" : "MaxPriceRange";
        let maxPriceUIDStr = listingSearchFilters?.PriceRangeList.filter(p => p.Code === priceCurreny).filter(p => p.Type === type).map(p => p.UID).join(",") ?? "";
        if (maxPriceUIDStr === null || maxPriceUIDStr === "")
            return;

        let maxPriceList = getListingSearchFilterLookupItemList(maxPriceUIDStr, lookupList)
            .filter(p => Number(p.Value) >= (value === "" ? 0 : Number(value)))
            .sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));

        if (state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString()) {
            setRentalMaxPriceMenuList(maxPriceList);
        } else {
            setMaxPriceMenuList(maxPriceList);
        }
    };

    const updatePriceRangeTitleLabel = (minPrice?: string | null, maxPrice?: string | null) => {
        if ((minPrice === "" || Number(minPrice) <= 0) && (maxPrice === "" || Number(maxPrice) <= 0)) {
            setPriceRangeTitleLabel(t('Search_PriceRange'));
        }
        else {
            const minPriceTitle = (minPrice && Number(minPrice) >= 0) ? formatCurrency(parseFloat(minPrice), '', siteSettings) : t("Search_AnyPriceRange");
            const maxPriceTitle = (maxPrice && Number(maxPrice) >= 0) ? formatCurrency(parseFloat(maxPrice), '', siteSettings) : t("Search_AnyPriceRange");
            setPriceRangeTitleLabel(`${minPriceTitle} - ${maxPriceTitle}`);
        }
    };

    // Filter DropDown Settings for Price Range
    const showPriceInQuickSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowPriceInQuickSearch')?.HasAccess === "1";
    const showPriceInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowPriceInSearch')?.HasAccess === "1";
    const showPriceRange = (page == PAGE_TYPE.HOME && showPriceInQuickSearchSetting) || (page == PAGE_TYPE.LISTINGS && showPriceInSearchSetting);


    // Filter DropDown Settings for Rooms
    /*
    const showRoomsContainerInQuickSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowRoomsContainerInQuickSearch')?.HasAccess === "1";
    const showRoomsContainerInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowRoomsContainerInSearch')?.HasAccess === "1";
    const showRoomContainer = (page == PAGE_TYPE.HOME && showRoomsContainerInQuickSearchSetting)  || (page == PAGE_TYPE.LISTINGS && showRoomsContainerInSearchSetting);

    */

    const showRoomsInQuickSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowRoomsInQuickSearch')?.HasAccess === "1";
    const showBedroomsInQuickSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowBedroomsInQuickSearch')?.HasAccess === "1";
    const showRoomsInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'HideNumOfBedroomsInSearch')?.HasAccess === "1";
    const showBedroomsInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'HideNumOfBedroomsInSearch')?.HasAccess === "0";
    const showRooms = (page == PAGE_TYPE.HOME && showRoomsInQuickSearchSetting) || (page == PAGE_TYPE.LISTINGS && showRoomsInSearchSetting);
    const showBedroom = (page == PAGE_TYPE.HOME && showBedroomsInQuickSearchSetting) || (page == PAGE_TYPE.LISTINGS && showBedroomsInSearchSetting);

    const showBathRoomsInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowBathRoomsInSearch')?.HasAccess === "1";
    const showBathRoomsInQuickSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowBathRoomsInQuickSearch')?.HasAccess === "1";
    const showBathRooms = (page == PAGE_TYPE.HOME && showBathRoomsInQuickSearchSetting) || (page == PAGE_TYPE.LISTINGS && showBathRoomsInSearchSetting);


    return (

        <>

            {/* Mobile Filter Dropdowns for Homepage */}
            {isMobile && (
                <>
                    {/* Property Type */}
                    <FormControl sx={{ minWidth: 120 }}>

                        <Select
                            sx={{ textAlign: 'left' }}
                            value={(state.searchState.MacroPropertyTypeUIDs && state.searchState.MacroPropertyTypeUIDs.length > 0) ? state.searchState.MacroPropertyTypeUIDs.split(",")[0] : '-1'}
                            onChange={handleMacroPropertyTypeChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >

                            <MenuItem value="-1">{t('Search_PropertyType')}</MenuItem>
                            {resMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.RESIDENTIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass.toString() === PROPERTY_TYPES.NOTSELECTED.toString()) && (
                                <ListSubheader value="-2">{t('Search_Residential')}</ListSubheader>
                            )}
                            {resMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.RESIDENTIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass.toString() === PROPERTY_TYPES.NOTSELECTED.toString()) && resMacroPropertyTypesMenuList.map((m) => (
                                <MenuItem key={m.MacroPropertyTypeUID} value={m.MacroPropertyTypeUID}>{m.DisplayValue}</MenuItem>
                            ))}
                            {commMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.COMMERCIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass.toString() === PROPERTY_TYPES.NOTSELECTED.toString()) && (
                                <ListSubheader value="-3">{t('Search_Commercial')}</ListSubheader>
                            )}

                            {commMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.COMMERCIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass.toString() === PROPERTY_TYPES.NOTSELECTED.toString()) && commMacroPropertyTypesMenuList.map((m) => (
                                <MenuItem key={m.MacroPropertyTypeUID} value={m.MacroPropertyTypeUID}>{m.DisplayValue}</MenuItem>
                            ))}
                            {landMacroPropertyTypesMenuList != null && landMacroPropertyTypesMenuList.length > 0 && (state.searchState.ListingClass === PROPERTY_TYPES.LAND.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass.toString() === PROPERTY_TYPES.NOTSELECTED.toString()) && (
                                <ListSubheader value="-2">{t('Search_Land')}</ListSubheader>
                            )}
                            {landMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.LAND.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass.toString() === PROPERTY_TYPES.NOTSELECTED.toString()) && landMacroPropertyTypesMenuList.map((m) => (
                                <MenuItem key={m.MacroPropertyTypeUID} value={m.MacroPropertyTypeUID}>{m.DisplayValue}</MenuItem>
                            ))}

                        </Select>

                    </FormControl>

                    {/* Bedrooms */}
                    {(showBedroom && state.searchState.ListingClass.toString() !== PROPERTY_TYPES.COMMERCIAL.toString()) && (

                        <FormControl sx={{ minWidth: 120 }}>

                            <Select
                                // label={t('Search_MinBed')}

                                onChange={handleBedroomChange}
                                value={state.searchState.Bedrooms ?? '-1'}
                                displayEmpty
                                sx={{ textAlign: 'left' }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >

                                <MenuItem value="-1">{t('Search_MinBed')}</MenuItem>
                                {bedroomsMenuList != null && bedroomsMenuList.map((b) => (
                                    <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    )}

                    {/* To Do:  Total Rooms base on multi Regions
                    {showRooms && (


                            <FormControl sx={{ minWidth: 120 }}>

                                <Select
                                    label={t('Search_Rooms')}
                                    onChange={handleBedroomChange}
                                    displayEmpty
                                    sx={{ textAlign: 'left' }}
                                    inputProps={{ 'aria-label':'Without label' }}
                                    value={state.searchState.Bedrooms ?? '-1'}
                                >

                                    <MenuItem value="-1">{t('Search_Rooms')}</MenuItem>
                                    {bedroomsMenuList != null && bedroomsMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}
                                </Select>

                            </FormControl>


                    )} */}

                    {/* Bathrooms */}
                    {(showBathRooms && state.searchState.ListingClass.toString() !== PROPERTY_TYPES.COMMERCIAL.toString()) && (
                        <Box>

                            <FormControl sx={{ minWidth: 120 }}>

                                <Select
                                    label={t('Search_Bathrooms')}
                                    onChange={handleBathroomChange}
                                    displayEmpty
                                    sx={{ textAlign: 'left' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={state.searchState.Bathrooms ?? '-1'}
                                >

                                    <MenuItem value="-1">{t('Search_Bathrooms')}</MenuItem>
                                    {bathroomsMenuList != null && bathroomsMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}

                                </Select>

                            </FormControl>
                        </Box>
                    )}

                    {/* Price Range */}
                    {showPriceRange && (

                        <>

                            <IconButton
                                disableTouchRipple
                                aria-label={t('Search_PriceRange')}
                                onClick={openPriceRangePopover}
                                sx={{
                                    minHeight: '56px !important',
                                    justifyContent: 'space-between',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    color: 'var(--body-text-color) !important',
                                    fontWeight: 400,
                                    paddingLeft: '14px !important',
                                    padding: '14px !important',
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'var(--font-family)',
                                    p: 1,
                                    '&:hover': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                        border: '2px solid var(--primary-color)',
                                    },
                                    '&:focus': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                        border: '2px solid var(--primary-color)',
                                    },
                                    '&:active': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                        border: '2px solid var(--primary-color)',
                                    },
                                }}
                            >
                                {<div dangerouslySetInnerHTML={{ __html: priceRangeTitleLabel }} />}

                                {priceRangePopoverOpen ? <ArrowDropUpOutlined />
                                    : <ArrowDropDownIcon />}
                            </IconButton>

                            <Popover
                                id={priceRangePopoverId}
                                open={priceRangePopoverOpen}
                                anchorEl={anchorEl}
                                onClose={closePriceRangePopover}
                                sx={{ top: { lg: '10px !important', xl: '10px !important' } }}
                                PaperProps={{
                                    sx: {
                                        maxHeight: '350px',
                                        overflowY: 'auto',
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {state.searchState.TransactionTypeUID !== TRANSACTION_TYPES.FORRENT.toString() ? (

                                    <Box p={2} width={300} sx={{ maxHeight: '350px !important' }}>

                                        <Grid container spacing={2}>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MinPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMin) > 0 ? Number(state.searchState.PriceMin) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMinPriceTextFieldChange}
                                                />

                                                {minPriceMenuList != null && minPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMinPriceMenuItemChange(p.Value)}>
                                                        {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)}
                                                    </MenuItem>
                                                ))}

                                            </Grid>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MaxPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMax) > 0 ? Number(state.searchState.PriceMax) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMaxPriceTextFieldChange}
                                                />

                                                {maxPriceMenuList != null && maxPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMaxPriceMenuItemChange(p.Value)}>
                                                        {p.DisplayValue}
                                                        {/* {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)} */}
                                                    </MenuItem>
                                                ))}

                                            </Grid>

                                        </Grid>

                                    </Box>

                                ) : (

                                    <Box p={2} width={300}>

                                        <Grid container spacing={2}>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MinPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMin) > 0 ? Number(state.searchState.PriceMin) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMinPriceTextFieldChange}
                                                />

                                                {rentalMinPriceMenuList != null && rentalMinPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMinPriceMenuItemChange(p.Value)}>
                                                        {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)}
                                                    </MenuItem>
                                                ))}
                                            </Grid>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MaxPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMax) > 0 ? Number(state.searchState.PriceMax) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMaxPriceTextFieldChange}
                                                />

                                                {rentalMaxPriceMenuList != null && rentalMaxPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMaxPriceMenuItemChange(p.Value)}>
                                                        {p.DisplayValue}
                                                        {/* {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)} */}
                                                    </MenuItem>
                                                ))}

                                            </Grid>

                                        </Grid>

                                    </Box>

                                )}

                            </Popover>

                        </>

                    )}
                </>

            )}

            {/* Desktop Filter Dropdowns */}
            {!isMobile && (

                <Stack direction="row" spacing={3} useFlexGap>

                    {/* Property Type */}
                    <Box>

                        <FormControl fullWidth>

                            <Select
                                label={t('Search_PropertyType')}
                                inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                onChange={handleMacroPropertyTypeChange}
                                variant="standard"
                                value={(state.searchState.MacroPropertyTypeUIDs && state.searchState.MacroPropertyTypeUIDs.length > 0) ?
                                    state.searchState.MacroPropertyTypeUIDs.split(",")[0] : '-1'}
                                inputRef={selectRef}
                            >

                                <MenuItem value="-1">{t('Search_PropertyType')}</MenuItem>
                                {resMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.RESIDENTIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString()) && (
                                    <ListSubheader value="-2">{t('Search_Residential')}</ListSubheader>
                                )}
                                {resMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.RESIDENTIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString()) && resMacroPropertyTypesMenuList.map((m) => (
                                    <MenuItem key={m.MacroPropertyTypeUID} value={m.MacroPropertyTypeUID}>{m.DisplayValue}</MenuItem>
                                ))}

                                {commMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.COMMERCIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString()) && (
                                    <ListSubheader value="-3">{t('Search_Commercial')}</ListSubheader>
                                )}

                                {commMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.COMMERCIAL.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString()) && commMacroPropertyTypesMenuList.map((m) => (
                                    <MenuItem key={m.MacroPropertyTypeUID} value={m.MacroPropertyTypeUID}>{m.DisplayValue}</MenuItem>
                                ))}
                                {landMacroPropertyTypesMenuList != null && landMacroPropertyTypesMenuList.length > 0 && (state.searchState.ListingClass === PROPERTY_TYPES.LAND.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString()) && (
                                    <ListSubheader value="-2">{t('Search_Land')}</ListSubheader>
                                )}
                                {landMacroPropertyTypesMenuList != null && (state.searchState.ListingClass === PROPERTY_TYPES.LAND.toString() || state.searchState.ListingClass === "" || state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString()) && landMacroPropertyTypesMenuList.map((m) => (
                                    <MenuItem key={m.MacroPropertyTypeUID} value={m.MacroPropertyTypeUID}>{m.DisplayValue}</MenuItem>
                                ))}

                            </Select>

                        </FormControl>

                    </Box>

                    {/* Bedrooms */}
                    {(showBedroom && state.searchState.ListingClass.toString() !== PROPERTY_TYPES.COMMERCIAL.toString()) && (
                        <Box>

                            <FormControl fullWidth>

                                <Select
                                    label={t('Search_MinBed')}
                                    inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                    onChange={handleBedroomChange}
                                    variant="standard"
                                    value={state.searchState.Bedrooms ?? '-1'}
                                >

                                    <MenuItem value="-1">{t('Search_MinBed')}</MenuItem>
                                    {bedroomsMenuList != null && bedroomsMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                        </Box>
                    )}

                    {/* To Do:  Total Rooms base on multi Regions
                    {showRooms && (
                        <Box>

                            <FormControl fullWidth>

                                <Select
                                    label={t('Search_Rooms')}
                                    inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                    onChange={handleBedroomChange}
                                    variant="standard"
                                    value={state.searchState.Bedrooms ?? '-1'}
                                >

                                    <MenuItem value="-1">{t('Search_Rooms')}</MenuItem>
                                    {bedroomsMenuList != null && bedroomsMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                        </Box>
                    )} */}

                    {/* Bathrooms */}
                    {(showBathRooms && state.searchState.ListingClass.toString() !== PROPERTY_TYPES.COMMERCIAL.toString()) && (
                        <Box>

                            <FormControl fullWidth>

                                <Select
                                    label={t('Search_Bathrooms')}
                                    inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                    onChange={handleBathroomChange}
                                    variant="standard"
                                    value={state.searchState.Bathrooms ?? '-1'}
                                >

                                    <MenuItem value="-1">{t('Search_Bathrooms')}</MenuItem>
                                    {bathroomsMenuList != null && bathroomsMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}

                                </Select>

                            </FormControl>
                        </Box>
                    )}

                    {/* Price Range */}
                    {showPriceRange && (

                        <>

                            <IconButton
                                disableTouchRipple
                                aria-label={t('Search_PriceRange')}
                                onClick={openPriceRangePopover}
                                sx={{
                                    color: 'var(--body-text-color)',
                                    fontWeight: 500,
                                    paddingTop: '0.25rem !important',
                                    paddingBottom: '0.3125rem !important',
                                    paddingLeft: '0 !important',
                                    paddingRight: '0 !important',
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'var(--font-family)',
                                    p: 1,
                                    '&:hover': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                    },
                                }}
                            >
                                {<div dangerouslySetInnerHTML={{ __html: priceRangeTitleLabel }} />}

                                {priceRangePopoverOpen ? <ArrowDropUpOutlined sx={{ color: 'rgba(0, 0, 0, 0.54) !important' }} />
                                    : <ArrowDropDownIcon sx={{ color: 'rgba(0, 0, 0, 0.54) !important' }} />}
                            </IconButton>

                            <Popover
                                id={priceRangePopoverId}
                                open={priceRangePopoverOpen}
                                anchorEl={anchorEl}
                                onClose={closePriceRangePopover}
                                sx={{ top: { lg: '10px !important', xl: '10px !important' } }}
                                PaperProps={{
                                    sx: {
                                        maxHeight: '350px',
                                        overflowY: 'auto',
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {state.searchState.TransactionTypeUID !== TRANSACTION_TYPES.FORRENT.toString() ? (

                                    <Box p={2} width={300} >

                                        <Grid container spacing={2}>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MinPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMin) > 0 ? Number(state.searchState.PriceMin) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMinPriceTextFieldChange}
                                                />

                                                {minPriceMenuList != null && minPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMinPriceMenuItemChange(p.Value)}>
                                                        {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)}
                                                    </MenuItem>
                                                ))}

                                            </Grid>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MaxPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMax) > 0 ? Number(state.searchState.PriceMax) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMaxPriceTextFieldChange}
                                                />

                                                {maxPriceMenuList != null && maxPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMaxPriceMenuItemChange(p.Value)}>
                                                        {p.DisplayValue}
                                                        {/* {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)} */}
                                                    </MenuItem>
                                                ))}

                                            </Grid>

                                        </Grid>

                                    </Box>

                                ) : (

                                    <Box p={2} width={300}>

                                        <Grid container spacing={2}>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MinPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMin) > 0 ? Number(state.searchState.PriceMin) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMinPriceTextFieldChange}
                                                />

                                                {rentalMinPriceMenuList != null && rentalMinPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMinPriceMenuItemChange(p.Value)}>
                                                        {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)}
                                                    </MenuItem>
                                                ))}
                                            </Grid>

                                            <Grid container item xs={6} direction="column">

                                                <TextField
                                                    label={t('Search_MaxPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMax) > 0 ? Number(state.searchState.PriceMax) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMaxPriceTextFieldChange}
                                                />

                                                {rentalMaxPriceMenuList != null && rentalMaxPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMaxPriceMenuItemChange(p.Value)}>
                                                        {p.DisplayValue}
                                                        {/* {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)} */}
                                                    </MenuItem>
                                                ))}

                                            </Grid>

                                        </Grid>

                                    </Box>

                                )}

                            </Popover>

                        </>

                    )}

                </Stack>

            )}

        </>
    );
};

export default ListingSearchFilterDropdowns;
