import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Chip } from '@mui/material';
import { useSearch } from '../../reducer/searchreducer';
import { RESET_SEARCH_STATE, SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { LISTINGSEARCHFILTER } from '../../constants/constants';
import { ListingSearchFilters, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { formatNumberWithRegionSetting } from '../utils/helper/FormatNumberHelper';
import { fetchListingFilters, fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchMacroPropertyTypesTranslations } from '../../services/TranslationService';
import { updateLocationUrl } from '../utils/helper/FunctionHelper';

interface ChipItem {
    filterName: string;
    value: string;
    label: string;
}

interface ListingSearchFilterChipProps {
    lookupList: LookupItem[];
}

const ListingSearchFilterChip: React.FC<ListingSearchFilterChipProps> = ({ lookupList }) => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useSearch();
    const [chipList, setChipList] = useState<ChipItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const location = useLocation();
    const navigate = useNavigate();

    const addChip = (filterName: string, value: string, label: string) => {
 
        setChipList(prev => [...prev, { filterName, value, label }]);
    };

    const delChip = (filterName: string, value?: string) => {
   
        setChipList(prev => prev.filter(chip => chip.filterName !== filterName || chip.value !== value));
    };

    const handleFilterUpdate = (filterName: string, value: string | null, label: string) => {
   

        delChip(filterName, value);
        if (value && value !== 'null' && value !== '-1') 
            {addChip(filterName, value, label);}
    };

    const updateChips = async () => {

        const listingfilter: ListingSearchFilters = await fetchListingFilters();
        const macroPropertyTypes = await fetchMacroPropertyTypesTranslations();
        const siteSettings = await fetchSiteSettings();
        setSiteSettings(siteSettings);

        // Clear all chips before updating
        setChipList([]);

        const filters = state.searchState;
        const chipsConfig = [
            { name: LISTINGSEARCHFILTER.MACROPROPERTYTYPEUIDS, value: filters.MacroPropertyTypeUIDs, lookupList: macroPropertyTypes},
            { name: LISTINGSEARCHFILTER.PRICEMIN, value: filters.PriceMin, format: (val: string) => t('Search_MinPrice') + " " + formatNumberWithRegionSetting(Number(val), siteSettings) },
            { name: LISTINGSEARCHFILTER.PRICEMAX, value: filters.PriceMax, format: (val: string) => t('Search_MaxPrice') + " " + formatNumberWithRegionSetting(Number(val), siteSettings) },
            { name: LISTINGSEARCHFILTER.PROPERTYFEATURES, value: filters.PropertyFeatures, listingfilter: listingfilter},
            { name: LISTINGSEARCHFILTER.BEDROOMS, value: filters.Bedrooms, format: (val: string) => t('Search_MinBed') + " " + val + "+" },
            { name: LISTINGSEARCHFILTER.BATHROOMS, value: filters.Bathrooms, format: (val: string) => t('Search_Bathrooms') + " " + val + "+" },
            { name: LISTINGSEARCHFILTER.PARKINGSPACES, value: filters.ParkingSpaces, format: (val: string) => t('Search_ParkingSpaces') + " " + val + "+" },
            { name: LISTINGSEARCHFILTER.MINTOTALAREA, value: filters.MinTotalArea, format: (val: string) => t('Search_MinSqM') + " " + val },
            { name: LISTINGSEARCHFILTER.MAXTOTALAREA, value: filters.MaxTotalArea, format: (val: string) => t('Search_MaxSqM') + " " + val },
            { name: LISTINGSEARCHFILTER.PROPERTIESADDED, value: filters.PropertiesAdded, format: (val: string) => t('Search_ShowPropertiesAdded') + " " + getLookupTranslationById(val, lookupList) },
            { name: LISTINGSEARCHFILTER.DEVELOPMENTID, value: filters.DevelopmentID, format: (val: string) =>  listingfilter?.DevelopmentList.find(d => d.DevelopmentID.toString() === filters.DevelopmentID)?.DevelopmentName ?? ""},
            { name: LISTINGSEARCHFILTER.MLSID, value: filters.MLSID, label: filters.MLSID },
            { name: LISTINGSEARCHFILTER.HASMULTIMEDIA,  value: filters.HasMultiMedia === 'true' ? filters.HasMultiMedia : null , format: (val: string) => t('Search_Multimedia'),},
            { name: "FreeText", value: filters.FreeText, format: (val: string) => filters.FreeText },
            { name: "StreetName", value: filters.StreetName, format: (val: string) => filters.StreetName }, // Add StreetName to chipsConfig
        ];


        for (const chipConfig of chipsConfig) {
            const { name, value, format, lookupList, listingfilter } = chipConfig;
            delChip(name);
            if (value && value !== '-1' && value !== 'null') {
                if (name === LISTINGSEARCHFILTER.STREETNAME)
                {
                    handleFilterUpdate(name, value, value); //  streetname has multi comma in the text and it is not array
                } else {
                    const values = value.split(',');
                    if (name === LISTINGSEARCHFILTER.PROPERTYFEATURES) {
                        values.forEach(val => {
                            const label = listingfilter?.ListingFeatureList?.find(p => p.FeatureID.toString() === val)?.FeatureName || "";
                            if (label && label !== "") {
                                handleFilterUpdate(name, val, t(label));
                            }
                        });
                    } else {
                        values.forEach(val => {
                            const label = format
                                ? format(val)
                                : lookupList
                                    ? getLookupTranslationById(val, lookupList)
                                    : val;
                            handleFilterUpdate(name, val, label);
                        });
                    }
                }
            } else {
                delChip(name);
            }
        }

        // Add CityNM, ProvinceNM, and LocalZoneNM chips
        if (Array.isArray(filters.CityNM)) {
            filters.CityNM.forEach((city, index) => {
                if (city && city !== 'null') {
                    handleFilterUpdate(`CityNM-${index}`, city, city.split('-')[1]);
                }
            });
        }
        if (Array.isArray(filters.ProvinceNM)) {
            filters.ProvinceNM.forEach((province, index) => {
                if (province && province !== 'null') {
                    handleFilterUpdate(`ProvinceNM-${index}`, province, province.split('-')[1]);
                }
            });
        }
        if (Array.isArray(filters.LocalZoneNM)) {
            filters.LocalZoneNM.forEach((localZone, index) => {
                if (localZone && localZone !== 'null') {
                    handleFilterUpdate(`LocalZoneNM-${index}`, localZone, localZone.split('-')[1]);
                }
            });
        }
    };

    useEffect(() => {
        updateChips();
    }, [state.searchState, i18n.language, location.search]);

    const updateSearchParams = (searchParams: URLSearchParams, key: string, values: string[]) => {
        searchParams.delete(key);
        values.forEach(value => {
            if (value.trim() !== 'NaN') {
                searchParams.append(key, value);
            }
        });
    };

    const updateFilterSearchParams = (searchParams: URLSearchParams, key: string, value: string) => {
        searchParams.delete(key);
        if (value !== null && value !== "" && value.trim() !== 'NaN' ) {
            searchParams.append(key, value);
        }
    };

    const removeSearchCriteria = (filterName: string, value?: string) => {

        delChip(filterName, value);
        const updatedState = { ...state.searchState };
        const searchParams = new URLSearchParams(location.search);

        if (filterName.startsWith('CityNM')) {
            updatedState.CityNM = Array.isArray(updatedState.CityNM) ? updatedState.CityNM.filter(city => city !== value) : [];
            updatedState.City = Array.isArray(updatedState.City) ? updatedState.City.filter(city => city.toString() !== value?.split('-')[0]) : [];
        } else if (filterName.startsWith('ProvinceNM')) {
            updatedState.ProvinceNM = Array.isArray(updatedState.ProvinceNM) ? updatedState.ProvinceNM.filter(province => province !== value) : [];
            updatedState.Province = Array.isArray(updatedState.Province) ? updatedState.Province.filter(province => province.toString() !== value?.split('-')[0]) : [];
        } else if (filterName.startsWith('LocalZoneNM')) {
            updatedState.LocalZoneNM = Array.isArray(updatedState.LocalZoneNM) ? updatedState.LocalZoneNM.filter(localZone => localZone !== value) : [];
            updatedState.LocalZone = Array.isArray(updatedState.LocalZone) ? updatedState.LocalZone.filter(localZone => localZone.toString() !== value?.split('-')[0]) : [];
        } else if (filterName === 'FreeText') {
            updatedState.FreeText = null;
        } else if (filterName === LISTINGSEARCHFILTER.MACROPROPERTYTYPEUIDS) {
            updatedState.MacroPropertyTypeUIDs = updatedState.MacroPropertyTypeUIDs.split(',').filter(uid => uid !== value).join(',');
        } else if (filterName === LISTINGSEARCHFILTER.PROPERTYFEATURES) {
            updatedState.PropertyFeatures = updatedState.PropertyFeatures.split(',').filter(feature => feature !== value).join(',');
        } else {
            updatedState[filterName] = null;
            searchParams.delete(filterName);
        }

        // Clear existing searchParams before appending new values
        searchParams.delete('City');
        searchParams.delete('CityNM');
        searchParams.delete('Province');
        searchParams.delete('ProvinceNM');
        searchParams.delete('LocalZone');
        searchParams.delete('LocalZoneNM');
        searchParams.delete('StreetName');
        searchParams.delete('MacroPropertyTypeUIDs');
        searchParams.delete('PropertyFeatures');
        searchParams.delete('PriceMax');
        searchParams.delete('PriceMin');
        searchParams.delete('Bedrooms');
        searchParams.delete('Bathrooms');
        searchParams.delete('ParkingSpaces');
        searchParams.delete('MinTotalArea');
        searchParams.delete('MaxTotalArea');
        searchParams.delete('PropertiesAdded');
        searchParams.delete('HasMultiMedia');
        searchParams.delete('MLSID');
        searchParams.delete('FreeText');
        // Update searchParams with the new state values
        updateSearchParams(searchParams, 'City', updatedState.City.map(city => city.toString()));
        updateSearchParams(searchParams, 'CityNM', updatedState.CityNM);
        updateSearchParams(searchParams, 'Province', updatedState.Province.map(province => province.toString()));
        updateSearchParams(searchParams, 'ProvinceNM', updatedState.ProvinceNM);
        updateSearchParams(searchParams, 'LocalZone', updatedState.LocalZone.map(localZone => localZone.toString()));
        updateSearchParams(searchParams, 'LocalZoneNM', updatedState.LocalZoneNM);

        if (updatedState.StreetName && updatedState.StreetName.trim() !== 'NaN') searchParams.set('StreetName', updatedState.StreetName);

        dispatch({ type: SET_SEARCH_STATE, payload: updatedState });
        navigate({ search: searchParams.toString() });

    };

    return (
        <Stack direction="row" spacing={1} mt={2} useFlexGap flexWrap={{ xs: 'nowrap', md: 'wrap' }} sx={{ overflowY: 'auto', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={1} className="listing-search-chip-container" useFlexGap flexWrap={{ xs: 'nowrap', md: 'wrap' }}>
                {chipList.map(chip => (
                    <Chip key={`${chip.filterName}-${chip.value}`} label={chip.label} onDelete={() => removeSearchCriteria(chip.filterName, chip.value)} />
                ))}
            </Stack>
        </Stack>
    );
};

export default ListingSearchFilterChip;
